import logo from './logo.svg';
import './App.css';
import { Routes, Route, Switch } from "react-router-dom"
import Navbartop from './component/inc/navbar'
import Home from './container/home'
import About from './container/about'
import Users from './container/users'
import Blog from './container/blog'
import BlogDetail from './container/blog-detail'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <div className="App">
    <Navbartop />
    <Routes>
        <Route path="/" exact element={ <Home/> } />
        <Route path="/aboutus" exact element={ <About/> } />
        <Route path="/users" exact element={ <Users/> } />
        <Route path="/blog" exact element={ <Blog/> } />
        <Route path="/blog-detail/:id" exact element={ <BlogDetail/> } />
    </Routes>
      {/* <Home/> */}
      {/* <About/> */}
      {/* <Users/> */}
    </div>
  );
}

export default App;
