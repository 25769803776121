import React, {Component} from "react";
import DynamicRouting from "../DynamicRouting";

class UserList extends Component {

    render() {
        return(
            <>
                <DynamicRouting />
            </>
        )
    }

}

export default UserList;