import React, {Component} from "react";
import { Row, Col, Card, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import BlogCard from "../../component/BlogCard";
import axios from 'axios';

class Blog extends Component {

    constructor(props){
        super(props);
            this.state = {
            data:[],
            searchVal: "",
            loading:true
        }
    }
    async componentDidMount(){
        try {
            await axios.get('https://jsonplaceholder.typicode.com/photos')
            .then((response)  =>  {
                const newData = response.data.slice(0, 15);
                this.setState({
                    data: newData,
                    // data:response.newData,
                    loading: false
                });
            })
        }
        catch(error){
            console.log(error);
        } 
    }

    render() {
        // console.log(this.state.data)
        const { data, searchVal } = this.state;
        return (
            <>
                <div className="container">
                    {
                        this.state.loading ? <p>Loading...</p> : 

                        <Row>
                        <Col lg={12} className="mt-5">
                        <Form className="d-flex">
                            <FormControl
                                type="search"
                                placeholder="Search Blog"
                                className="me-2"
                                aria-label="Search"
                                onChange={(v)=> this.setState({ searchVal: v.target.value })} />
                            <Button variant="outline-success">Search</Button>
                        </Form>
                        </Col>
                            {/* {console.log(this.state.data)} */}
                                {this.state.data.filter(name => name.title.includes(searchVal)).map((d, i) => 
                                <Col lg={4}>
                                    <BlogCard 
                                    id={d.id} 
                                    title={d.title} 
                                    url={d.url}
                                    text={d.text}
                                    />
                                </Col>
                            )}
                        </Row>
                    }

                </div>
            </>
        )
    }
}
export default Blog;