import React from "react";
import {BrowserRouter as Router, Link, Route} from 'react-router-dom';
import User from "./user"

function DynamicRouting(){
    let users = [
        {id:1, name: 'Test 1', email: "test1@test.com"},
        {id:2, name: 'Test 2', email: "test2@test.com"},
        {id:3, name: 'Test 3', email: "test3@test.com"},
        {id:4, name: 'Test 4', email: "test4@test.com"},
        {id:5, name: 'Test 5', email: "test5@test.com"},
    ]

    return (
        <>
            <div className="container">
            {/* <Router> */}
                <h1>React Dynamic Routing</h1>
                
                {
                    users.map((item)=>
                    <div>
                       <Link to={"/users/"+item.id}>{item.name}</Link>
                    </div>
                    )
                }
                {/* <Route path="/user:id"> <User /> </Route> */}
                {/* </Router> */}
            </div>
        </>
    );
}


export default DynamicRouting;