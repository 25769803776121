import React, {Component} from "react";
import UserList from "./UserList"

class Users extends Component{

    constructor() {
        super()
        this.state={
            toggle:true
        }
    }

    deleteUser=()=>{
        this.setState(
            {
                toggle:!this.state.toggle
            }
        )
    }

    render (){
        return (
            <>
                <div className="container">
                    {/* <h1>Users page</h1> */}
                    {
                        this.state.toggle?
                        <UserList />:null
                    }
                    {/* <button onClick={this.deleteUser}>Delete User</button> */}
                </div>
            </>
        )
    }
}

export default Users;