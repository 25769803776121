import React, {Component} from "react";

class Home extends Component {

    constructor(props){
        super(props);
            this.state={
            name:"Nafish",
            city:"Rewa",
            age:25,
        }
    }

    handleClick=()=>{
        this.setState({
        name:"Mohit",
        city:"Indore",
        age:24
        })
        console.log("hello")
    }

    render() {
        const {name, city, age} = this.state
            return (
            <div className={"container"}>
                <div>
                    <div className={'row'}>
                    <h1>Name: {name}</h1>
                    <h2>City: {city}</h2>
                    <h2>Age: {age}</h2>
                    <button className="btn btn-outline-success" onClick={this.handleClick}>Click Me</button>
                </div>
            </div>
        </div>
        );
    }
}

export default Home;