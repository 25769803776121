import React from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { NavLink } from "react-router-dom";
import {Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'; 


const Navbartop = () => {
  return (
    <>
   
      {/* <Link to="/">Home </Link>
      <Link to="aboutus">About </Link>
      <Link to="users">Users</Link> */}
      
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand to="/">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavLink className="nav-link" to="/">Home</NavLink>
              <NavLink className="nav-link" to="/aboutus">About</NavLink>
              <NavLink className="nav-link" to="/users">Users</NavLink>
              <NavLink className="nav-link" to="/blog">Blog</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Navbartop;