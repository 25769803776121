import React, { Component } from "react";
import { Row, Col, Card, Button, InputGroup, FormControl } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';

class BlogCard extends Component {
    render() {
        return ( 
            <>
                
                <Card className="blog_item">
                    <Link to={`/blog-detail/${this.props.id}`}>
                        <Card.Img variant="top" src={this.props.url} />
                    </Link>
                    <Card.Body>
                        <Card.Title>{this.props.title}</Card.Title>
                        <Card.Text>
                        {this.props.text}
                        </Card.Text>
                        <Link to={`/blog-detail/${this.props.id}`}>Go somewhere</Link>
                    </Card.Body>
                </Card>
            </>
        )
    }
}
export default BlogCard;